// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-average-investor-js": () => import("./../../../src/pages/average-investor.js" /* webpackChunkName: "component---src-pages-average-investor-js" */),
  "component---src-pages-elliot-wave-js": () => import("./../../../src/pages/elliot-wave.js" /* webpackChunkName: "component---src-pages-elliot-wave-js" */),
  "component---src-pages-fishermen-js": () => import("./../../../src/pages/fishermen.js" /* webpackChunkName: "component---src-pages-fishermen-js" */),
  "component---src-pages-food-for-thought-js": () => import("./../../../src/pages/food-for-thought.js" /* webpackChunkName: "component---src-pages-food-for-thought-js" */),
  "component---src-pages-golden-ratio-js": () => import("./../../../src/pages/golden-ratio.js" /* webpackChunkName: "component---src-pages-golden-ratio-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-newsletters-js": () => import("./../../../src/pages/newsletters.js" /* webpackChunkName: "component---src-pages-newsletters-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-testimonials-js": () => import("./../../../src/pages/testimonials.js" /* webpackChunkName: "component---src-pages-testimonials-js" */),
  "component---src-pages-totm-history-js": () => import("./../../../src/pages/totm-history.js" /* webpackChunkName: "component---src-pages-totm-history-js" */),
  "component---src-pages-totm-ideas-js": () => import("./../../../src/pages/totm-ideas.js" /* webpackChunkName: "component---src-pages-totm-ideas-js" */),
  "component---src-pages-totm-school-js": () => import("./../../../src/pages/totm-school.js" /* webpackChunkName: "component---src-pages-totm-school-js" */),
  "component---src-pages-trader-psy-js": () => import("./../../../src/pages/trader-psy.js" /* webpackChunkName: "component---src-pages-trader-psy-js" */)
}

